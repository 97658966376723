import { FetchJSON } from "./Fetch";

export async function fetchAccessForUser(user, slug, invite) {
  if(slug==='folder-root')
  {
    if(user.isLoggedIn)
    {
      return Promise.resolve({
        me: {
          role: "owner",
          hasAccess: true,
        },
        subject: {
          accessLevel: "private",
          hasSetAccessLevel: true,
        },
      });
    }else
    {
      return Promise.resolve({
        me: {
          hasAccess: false,
        },
        subject: {
          accessLevel: "private",
        },
      });
    }
  }

  return new Promise((resolve, reject) => {
    const invitePart = invite ? `?inviteKey=${invite}` : '';
    const [promise] = user.FetchJSON(`${process.env.API_URL}/access/${slug}/accessData${invitePart}`);
    promise.then(({data}) => {
      resolve(data);
    })
    .catch(e => {
      reject(e);
    })
  });
}
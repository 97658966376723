import css from 'styled-jsx/css';

export const style = css`
  .app-install-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3000;
    display: flex;
    justify-content: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: white;
    color: rgba(var(--accentColorDark), 1);
    border: 1px solid rgba(var(--border1), 1);
    transition: transform 0.3s ease-out;
    margin: 15px;
    margin-bottom: max(15px, calc(15px + env(safe-area-inset-bottom)));
    border-radius: 8px;
  }

  .title {
    font-size: 18px;
    margin-bottom: 2px;
    color: rgba(var(--accentColorDark), 1);
  }

  .text {
    margin-bottom: 20px;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 20px;
    padding: 5px 15px;
    text-decoration: none;
    background-color: transparent;
    border: 0;
    font-size: 14px;
    cursor: pointer;
  }

  .button.outline {
    border: 1px solid rgba(var(--accentColorDark), 1);
    opacity: 0.75;
    color: rgba(var(--accentColorDark), 1);
  }

  .button.filled {
    background-color: rgba(var(--accentColor), 1);
  }
`;
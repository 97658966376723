import { useCallback, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, MenuBar } from "components/UI";
import { whenAuthenticated } from "components/Authentication";
import style from './style';
import { useUser } from "utils/user/lib";
import {fetchAccessForUser} from 'utils/access';
import { useRouter } from "next/router";
import translationStore from "stores/translation-store";
import { observer } from "mobx-react";
import { faEnvelopeCircleCheck } from "@fortawesome/pro-duotone-svg-icons/faEnvelopeCircleCheck";
import { NextSeo } from "next-seo";
import AppInstallBar from "components/AppInstallBar/AppInstallBar";

function NoAccess(props) {
  const {labels, slug, invite, access} = props;
  const {subject} = access;
  const [slugType, slugKey] = slug.split('-');
  const user = useUser();
  const router = useRouter();
  const [accessRequestSent, setAccessRequestSent] = useState(false);
  const canAccess = access.me?.canAccess;

  const prefix = canAccess ? 'can-access' : 'no-access';
  const description = translationStore.translate(`${prefix}.${slugType}.description`);
  const button = translationStore.translate(`${prefix}.button`);
  const loginText = translationStore.translate(`no-access.${slugType}.logintext`);

  const onClick = useCallback(() => {
    whenAuthenticated(user, {
      message: loginText,
    })
    .then(() => {
      fetchAccessForUser(user, slug, invite)
      .then(data => {        
        if(!data.me.hasAccess)
        {
          // Ingelogd maar nog geen access helaas
          requestAccess();
        }
      });
    })
    .catch(() => {});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const requestAccess = useCallback(() => {
    if(!user.isLoggedIn)
      return;
    
    const [promise] = user.FetchJSON(`${process.env.API_URL}/access/${slug}/join`,  {
      data: {
        inviteKey: invite,
      },
      method: "POST"
    });
    promise.then((res) => {
      if(res.data.hasAccess)
      {
        const query = {...router.query};
        delete query.invite;
        router.replace({pathname: router.pathname, query});
      }else if(res.data.requestSent)
      {
        setAccessRequestSent(true);
      }
    })
  }, [slug, user, invite]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if(!accessRequestSent)
      return;

    // Access Request verstuurd, pollen of je al toegang hebt
    let poll = true;
    let timer = null;
    function pollAccess() {
      if(!poll)
        return;
      
      fetchAccessForUser(user, slug)
      .then(data => {
        if(data.me.hasAccess)
        {
          router.replace(router.asPath);
        }else
        {
          if(poll)
          {
            timer = setTimeout(() => {
              pollAccess();
            }, 1000);
          }
        }
      });
    }
    
    pollAccess();

    return () => {
      poll = false;
      if(timer)
        clearTimeout(timer);
    }
  }, [accessRequestSent]); // eslint-disable-line react-hooks/exhaustive-deps

  let content = useMemo(() => {
    if(accessRequestSent)
    {
      return (
        <div className="content">
          <div className="icon">
            <FontAwesomeIcon icon={faEnvelopeCircleCheck} size={"4x"} />
          </div>

          <h1>{translationStore.translate('no-access.requestsent.title')}</h1>
          <h2>{translationStore.translate('no-access.requestsent.text', {title: subject.title})}</h2>
          <style jsx>{style}</style>
        </div>
      );
    }

    if(canAccess) {
      return (
        <div className="content green">
          <h1>{subject.title}</h1>
          {subject.subtitle?.length>0 && <h2>{subject.subtitle}</h2>}

          <div className="labels">
            {
              labels.map(label => {
                return (
                  <div className="label" key={label.label}>
                    <FontAwesomeIcon icon={label.icon} />{label.label}
                  </div>
                );
              })
            }
          </div>

          <div style={{height: 15}} />

          <Button className="green large" style={{width: 275}} onClick={onClick}>{button}</Button>
          <div className="description">{description}</div>

          <style jsx>{style}</style>
        </div>
      )
    }else
    {
      return (
        <div className="content">
          <h1>{subject.title}</h1>
          {subject.subtitle?.length>0 && <h2>{subject.subtitle}</h2>}

          <div className="labels">
            {
              labels.map(label => {
                return (
                  <div className="label" key={label.label}>
                    <FontAwesomeIcon icon={label.icon} />{label.label}
                  </div>
                );
              })
            }
          </div>

          <div style={{height: 60}} />

          <Button className="accent large" style={{width: 275}} onClick={onClick}>{button}</Button>
          <div className="description">{description}</div>

          <style jsx>{style}</style>
        </div>
      );
    }
  }, [accessRequestSent, canAccess]); // eslint-disable-line react-hooks/exhaustive-deps

  const coverData = useMemo(() => {
    if(!access.subject?.images?.sizes) {
      return;
    }

    const cover = access.subject.images.sizes.reduce((prev, curr) => {
      if(curr.width > prev.width)
        return curr;
      return prev;
    });

    return cover;
  }, [access]);

  return (
    <>
      <NextSeo
        title={props.access.subject.title}
        description={props.access.subject.subtitle || " "}
        openGraph={{
          title: props.access.subject.title,
          description: props.access.subject.subtitle,
          url: `${process.env.SITE_URL}/${slugType}/${slugKey}/`,
          images: [{
            url: coverData?.url,
            width: coverData?.width,
            height: coverData?.height,
          }]
        }}
      />

      <MenuBar
        key="menubar"
        visible={true}
      >
        <div key="left">
          <MenuBar.Back />
        </div>

        <div key="mobile">
          <MenuBar.Back key="shortback" />
          <MenuBar.Cart />
        </div>

        <div key="center">
          <MenuBar.Logo />
        </div>

        <div key="right">
          <MenuBar.Cart />
        </div>
      </MenuBar>

      <div style={{height: 65}}></div>

      <AppInstallBar />

      {content}
    </>
  )
}

NoAccess.defaultProps = {
  labels: [],
}

export default observer(NoAccess);
import ErrorPage from "components/ErrorPage";
import { Observer } from "mobx-react";
import translationStore from "stores/translation-store";
import { createTranslationsFromContext } from "utils/translations/lib";

function NotFound()
{
  return (
    <Observer>
      {
        () => <ErrorPage title={translationStore.translate("404.title")} />
      }
    </Observer>
  );
}

export async function getStaticProps(context) {
  const translations = await createTranslationsFromContext(context);

  return {
    props: {
      user: {},
      translations: translations.JSON,
    }
  }
}

export default NotFound;
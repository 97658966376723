import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";

type setValue<T> = Dispatch<SetStateAction<T>>

declare global {
  interface WindowEventMap {
    'session-storage': CustomEvent
  }
}

export default function useSessionStorage<T>(key: string, initial: T): [value: T, setValue: (setValue<T>), loaded: boolean] {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [_value, _setValue] = useState<T>(initial);

  const readValue = useCallback((): T => {
    const value = window.sessionStorage.getItem(key);
    if (value) {
      return parseJSON(value);
    }

    return initial;
  }, [key]); // eslint-disable-line react-hooks/exhaustive-deps

  const setValue = useCallback((value: T) => {
    const newValue = value instanceof Function ? value(_value) : value

    window.sessionStorage.setItem(key, JSON.stringify(newValue));
    window.dispatchEvent(new Event('session-storage'));
    _setValue(newValue);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleStorageChange = useCallback(
      (event: StorageEvent | CustomEvent) => {
        if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
          return
        }
        _setValue(readValue())
      },
      [key, readValue],
    )

  useEffect(() => {
    _setValue(readValue());
    setLoaded(true);
  }, [key]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('session-storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('session-storage', handleStorageChange);
    };
  }, [key]); // eslint-disable-line react-hooks/exhaustive-deps

  return [_value, setValue, loaded];
}

// A wrapper for "JSON.parse()"" to support "undefined" value
function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '')
  } catch {
    console.log('parsing error on', { value })
    return undefined
  }
}
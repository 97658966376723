import NoSsr from 'components/NoSsr';
import useSessionStorage from 'hooks/useSessionStorage';
import { useCallback } from 'react';
import translationStore from 'stores/translation-store';
import { useUser } from 'utils/user/lib';
import { style } from './style'

export default function AppInstallBar() {
  const user = useUser();
  const [hidden, setHidden, loaded] = useSessionStorage("hideAppInstallBar", false);
  const hasTouch = typeof window !== "undefined" && "ontouchstart" in document;
  const isMobile = typeof window !== "undefined" && (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || (/Safari/.test(navigator.userAgent) && hasTouch));

  const onClose = useCallback(() => {
    setHidden(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if(user.features.appBar !== "on"){
    return null;
  }

  if(!loaded || hidden || !isMobile) {
    return null;
  }

  return (
    <NoSsr>
      <div className={"app-install-bar"}>
        <div className='content'>
          <div className='title'>{translationStore.translate("appinstallbar.title")}</div>
          <div className='text'>{translationStore.translate("appinstallbar.text")}</div>

          <div className='buttons'>
            <button className='button outline' onClick={onClose}>{translationStore.translate("appinstallbar.btn-no")}</button>
            <a className="button filled" href={`${process.env.CLICK_URL}/app/?url=${window.location.href}`}>{translationStore.translate("appinstallbar.btn-yes")}</a>
          </div>
        </div>
      </div>
      <style jsx>{style}</style>
    </NoSsr>
  );
}
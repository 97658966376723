import { MenuBar } from 'components/UI'

function ErrorPage(props)
{
  return (
    <div className="error-page">
      <MenuBar>
        <div key="center">
          <MenuBar.Logo />
        </div>

        <div key="mobile">
          <MenuBar.Logo />
        </div>
      </MenuBar>

      <div className="content">
        <h1>
          {props.title}
        </h1>
        {props.description && <p>{props.description}</p>}
      </div>
      <style global jsx>
      {`
        .error-page {
          min-height: inherit;
          height: inherit;
          display: flex;
          flex: 1;
          flex-direction: column;
        }

        .error-page>div {
          display: flex;
          flex: 1;
          flex-direction: column;
        }

        .error-page h1 {
          font-family: 'Source Sans Pro', sans-serif;
          font-weight: 300;
        }

        .error-page nav.ui-menu-bar {
          justify-content: center;
        }

        .error-page .content {
          display: flex;
          flex: 1;
          justify-content: center;
          align-items: center;
          background: transparent url(${process.env.ASSET_URL}/_static/error.jpg) no-repeat center right;
        background-size: 50vw auto;
        padding-left: 30px;
        padding-right: 30px;

        }
        @media (min-width: 1080px) {
          .error-page .content {
            background-size: auto;
          }
        }
      `}
      </style>
    </div>
  );
}

export default ErrorPage;